import { useLazyQuery, useQuery }          from '@apollo/client'
import { ArrowUturnLeftIcon }              from '@heroicons/react/24/solid'
import { useEffect, useState }             from 'react'
import { useDispatch, useSelector }        from 'react-redux'
import { useNavigate, useParams }          from 'react-router-dom'
import { Button }                          from '../../components'
import CommunityForumRecentQuestions       from '../../components/Forum/CommunityForumRecentQuestions'
import CommunityForumThreadConversationTab from '../../components/Forum/CommunityForumThreadConversationTab'
import CommunityForumThreadMediaTab        from '../../components/Forum/CommunityForumThreadMediaTab'
import CommunityForumUserInfo              from '../../components/Forum/CommunityForumUserInfo'
import { LoadingEllipsis }                         from '../../components/Loaders/Loaders'
import { setForumRanks }                           from '../../features/forum/communityForumSlice'
import { setToasts, showToaster }                  from '../../features/toaster/toasterSlice'
import { GET_FORUM_RANKS, GET_FORUM_THREAD_BY_ID } from '../../graphql/communityForum'
import MainLayout                                  from '../../layouts/MainLayout'

export default function CommunityForumThread(): JSX.Element {
  const { thread_id }                                   = useParams()
  const dispatch: any                                   = useDispatch()
  const navigate: any                                   = useNavigate()
  const forumRanks: any                                 = useSelector( ( state: any ): any => state.communityForum.ranks )
  const [ forumThread, setForumThread ]                 = useState( null )
  const [ activeTab, setActiveTab ]                     = useState( 'conversation' )
  const [ showRecentQuestions, setShowRecentQuestions ] = useState( true )

  const { loading: loadingThread, error: errorLoadingThread, refetch } = useQuery( GET_FORUM_THREAD_BY_ID, {
    variables:   { input: { id: thread_id } },
    onCompleted: async ( { forumThreadById }: any ): Promise<void> => {
      if ( !forumThreadById ) {
        navigate( '/forum' )
        dispatchMessage( 'error', 'Question not found!' )
      } else {
        setForumThread( forumThreadById )
      }
    }
  } )

  const [ getRanks, { loading: loadingRanks, error: errorLoadingRanks } ] = useLazyQuery( GET_FORUM_RANKS, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumRanks }: any ): void => dispatch( setForumRanks( forumRanks ) )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !forumRanks?.length ) {
      getRanks()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ forumThread ] )

  return (
    <MainLayout>
      { ( loadingThread || loadingRanks ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4" /> }
      { ( !loadingThread && !loadingRanks ) &&
        <div className="max-w-screen-3xl mx-auto w-full">
          <div className="flex-grow lg:p-10 p-3 space-y-4 2xl:space-y-8">
            { forumThread && forumRanks &&
              <div className="flex flex-col 2xl:flex-row 2xl:space-x-8">
                <div className="w-full 2xl:w-2/3 space-y-4 2xl:space-y-8">
                  <div className="col flex justify-between w-full">
                    <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
                      <Button
                        label="Back"
                        blue
                        onClick={ (): any => navigate( -1 ) }
                        icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
                      />
                    </div>

                    <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
                      <button
                        className="w-full h-full px-3 py-1 flex items-center justify-center bg-blue-secondary"
                        onClick={ (): void => setActiveTab( 'conversation' ) }
                      >
                        <span>Conversation</span>
                      </button>
                      <button
                        className="w-full h-full px-3 py-1 flex items-center justify-center bg-blue-secondary"
                        onClick={ (): void => setActiveTab( 'media' ) }
                      >
                        <span>Media</span>
                      </button>
                    </div>
                  </div>

                  { ( activeTab === 'conversation' ) &&
                    <CommunityForumThreadConversationTab forumThread={ forumThread } refetch={ refetch } />
                  }

                  { ( activeTab === 'media' ) &&
                    <CommunityForumThreadMediaTab threadId={ forumThread.id } />
                  }
                </div>
                <div className="mt-4 2xl:mt-0 w-full 2xl:w-1/3 space-y-4 2xl:space-y-8">
                  <div className="row flex flex-col items-center w-full">
                    <div className="flex flex-col justify-center bg-white shadow-soft rounded-3xl w-full p-4 2xl:p-8">
                      <CommunityForumUserInfo user={ forumThread.author } />
                    </div>
                  </div>
                  { showRecentQuestions &&
                    <div className="row flex flex-col items-center w-full">
                      <div className="flex flex-col justify-center bg-white shadow-soft rounded-3xl w-full p-4 2xl:p-8">
                        <CommunityForumRecentQuestions header="Other questions from this user:" excludedId={ forumThread.id } user={ forumThread.author } qty={ 5 } setShowRecentQuestions={ setShowRecentQuestions } />
                      </div>
                    </div>
                  }
                </div>
              </div>
            }

            { errorLoadingRanks &&
              <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
                <b>Error occurred when fetching Forum Ranks:</b>
                { errorLoadingRanks.graphQLErrors.map( ( { message }: any, i: number ): any => (
                  <div key={ ( i + 1000 ) } className="flex w-full"><span>{ message }</span></div>
                ) ) }
              </div>
            }

            { errorLoadingThread &&
              <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
                <b>Error occurred when fetching Forum Thread:</b>
                { errorLoadingThread.graphQLErrors.map( ( { message }: any, i: number ): any => (
                  <div key={ i } className="flex w-full"><span>{ message }</span></div>
                ) ) }
              </div>
            }
          </div>
        </div>
      }
    </MainLayout>
  )
}
